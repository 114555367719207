@font-face {
    font-family: Spoiler;
    src: url("/assets/font/Spoiler.woff") format("woff");
    font-weight: 400;
    unicode-range: U+0590-05FF;
    /* Hebrew glyphs */
}

@font-face {
    font-family: Spoiler;
    src: url("/assets/font/SpoilerLight.woff") format("woff");
    font-weight: 200;
    unicode-range: U+0590-05FF;
    /* Hebrew glyphs */
}

@font-face {
    font-family: Spoiler;
    src: url("/assets/font/SpoilerBold.woff") format("woff");
    font-weight: 600;
    unicode-range: U+0590-05FF;
    /* Hebrew glyphs */
}

@font-face {
    font-family: Spoiler;
    src: url("/assets/font/SpoilerBlack.woff") format("woff");
    font-weight: 800;
    unicode-range: U+0590-05FF;
    /* Hebrew glyphs */
}


/* @font-face {
    font-family: Spoiler;
    src: url("/assets/font/SpoilerBlack.woff") format("woff");
    font-weight: 800;
    unicode-range: U+0590-05FF;
    /* Hebrew glyphs * /
} */


/* @font-face {
    font-family: Spoiler;
    src: url("/assets/font/SpoilerBlack.woff") format("woff");
    font-weight: bolder;
    unicode-range: U+0590-05FF; /* Hebrew glyphs * /
} */

@font-face {
    font-family: Spoiler;
    src: url("/assets/font/EnSpoilerRegular.woff") format("woff");
    font-weight: 400;
    unicode-range: U+000-5FF;
    /* Latin glyphs */
}

@font-face {
    font-family: Spoiler;
    src: url("/assets/font/EnSpoilerRegular.woff") format("woff");
    font-weight: 200;
    unicode-range: U+000-5FF;
    /* Latin glyphs */
}

@font-face {
    font-family: Spoiler;
    src: url("/assets/font/EnSpoilerBold.woff") format("woff");
    font-weight: 600;
    unicode-range: U+000-5FF;
    /* Latin glyphs */
}

@font-face {
    font-family: Spoiler;
    src: url("/assets/font/EnSpoilerBold.woff") format("woff");
    font-weight: 800;
    unicode-range: U+000-5FF;
    /* Latin glyphs */
}


/* @font-face {
    font-family: SpoilerLight;
    src: url("/assets/font/SpoilerLight.woff") format("woff");
} */


/* for numeric */

@font-face {
    font-family: Spoiler;
    src: url("/assets/font/Spoiler.woff") format("woff");
    font-weight: 400;
    unicode-range: U+0020-003F;
    /* Basic digits and symbols glyphs */
}

@font-face {
    font-family: Spoiler;
    src: url("/assets/font/SpoilerLight.woff") format("woff");
    font-weight: 200;
    unicode-range: U+0020-003F;
    /* Basic digits and symbols glyphs */
}

@font-face {
    font-family: Spoiler;
    src: url("/assets/font/SpoilerBold.woff") format("woff");
    font-weight: 600;
    unicode-range: U+0020-003F;
    /* Basic digits and symbols glyphs */
}

@font-face {
    font-family: Spoiler;
    src: url("/assets/font/SpoilerBlack.woff") format("woff");
    font-weight: 800;
    unicode-range: U+0020-003F;
    /* Basic digits and symbols glyphs */
}


/* @font-face {
    font-family: Spoiler;
    src: url("/assets/font/SpoilerBlack.woff") format("woff");
    font-weight: 800;
    unicode-range: U+0020-003F;
    /* Basic digits and symbols glyphs * /
} */