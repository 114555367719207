/* You can add global styles to this file, and also import other style files */

body {
    margin: 0;
    background-image: url(/assets/dashground.jpg);
    background-repeat: repeat-x;
    background-size: 100%;
    background-position-y: 38px;
    background-origin: border-box;
}

* {
    font-family: 'Spoiler', sans-serif;
}

@media screen and (min-width: 0px) and (max-width: 1000px) {
    body {
        background-image: url(/assets/dashground-mobile.jpg);
    }
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}